.app-body{
  background-color: #fff !important;
}
.add_user_button{
  /*background: #f0f2f5;*/
  background: #00a1ff;
  border: 1px solid #00a1ff;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px ;
  border-radius: 2px;
  color: white;
  align-items: center;
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
  height: 100% !important;
}
.ant-table-thead{
  background-color:#f0f2f5;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-items: flex-end;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #f0f2f5;
}

.active {
  color: white !important; /* details active */
  border-radius: 16px  16px  16px 16px !important;
  width: 100% !important;
  height: 100% !important;
  font-weight: bold;
  /* color: black !important; */
  background-color: #00a1ff !important;
 }
 .ant-menu-item-icon{
    color: black;
    font-weight: bold;
 }
 
/* .active span{
  color: #cef5e8;
} */

.active:hover{
  background-color:#cef5e8;
} 
/*#f0f2f5*/
.tableColor{
  color:#f0f2f5;
  background-color:#f0f2f5;
} 

 .ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .active
.ant-menu > .ant-menu-submenu-selected {
  color: green !important;
}

/* ////////////////////// */
.ant-menu-item-selected , .ant-menu-item-selected .nav-text .active{
  color: black !important;
  background-color: #00a1ff !important; /* background color */
}


/* media query */

@media only screen and (max-width: 600px) {
  .sidebar-container {
    width: 0px !important;
    background-color: green;
    display: none !important;
  }
  .ant-layout-sidebar{
    display: none !important;
    height: 100% !important;
  }
  .ant-layout-has-sidebar{
    display: none !important;
    height: 100% !important;
  }

  .ant-layout .app-body{
    /*display: none !important;*/
    height: 100% !important;
    /*height: 100vh;*/
  }

  .site-layout{
    margin-left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin-top: 20px !important;
    /*height: 100vh;*/
  }
  .navbar-container{
    width: 100% !important;
    padding-left: 5px !important;
    padding-right: 0px !important;
    background-color: #fff !important;
    position: fixed !important;
    z-index: 1 !important;

  }
  .body_content{
    padding: 16px !important;
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 640px) {
  .sidebar-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
  }
  .site-layout{
    margin-left: 270px !important;
    padding: 0 24px 24px !important;
    overflow: auto;
    height: 100vh;  /********/
  }
  .navbar-container{
    position: fixed !important;
    z-index: 1 !important;
    width: 100% !important;
    background: #fff !important;
  }
  .body_content{
    padding: 16px !important;
    margin-top: 50px !important;
  }
}
