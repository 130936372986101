

.worxx_logo{
    width: 250px !important;
    height: 60px !important;
    object-fit: contain;
    background-color: #00a1ff;
    /*justify-content: flex-center; #00a1ff*/
}
.ant-layout-header {
    height: 64px;
    padding: 0px;
    padding-right: 80px;
    color: rgba(0,0,0,.85);
    line-height: 64px;
    background: #001529;
}

.ant-dropdown-menu {
    position: relative;
    margin: 40px;
    padding: 4px;
}
@media only screen and (max-width: 600px) {
    .navbar-menu{
        width: 140px !important;
        margin-top: -10px !important;
    }
    .navbar-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
    }
}


@media only screen and (min-width: 610px) {
    .navbar-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}