
.user_image {
  width: 46px;
  border-radius: 50%;
}

.admin_modal_style{
  width: 40% !important;
}

.admin_upload_icon{
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: #90EE90;
    text-align: center;
    right: 218px;
    top: 125px;
    color: wheat;
    border: white 2px solid;
}

.admin_upload_icon_test {
  border-radius: 50%;
  background: #90EE90;
  color: wheat;
  border: white 2px solid;
  width: 38px;
  height: 38px;
    
}


.profile_container {
    border: 1px solid #90EE90;
    border-radius: 8px;
    box-sizing: border-box;
}

.active_status_style{
    color: rgb(20, 90, 20);
    border: 1px solid rgb(20, 90, 20);
}

.loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.pending_status_style{
    color: rgb(255, 0, 221);
    border: 1px solid rgb(255, 0, 221);
}

.completed_status_style{
    color: rgb(29, 27, 27);
    border: 1px solid rgb(29, 27, 27);
}
.cancelled_status_style{
    color: rgb(121, 8, 8);
    border: 1px solid rgb(121, 8, 8);
}

@media only screen and (max-width: 600px) {
  .profile_container{
      width: 98% !important;
  }
}